import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"

import Spacer from "../spacer"
import Container from "../container"

import Icon1 from "../../assets/images/temp/calculator-icon-1.svg"
import Icon2 from "../../assets/images/temp/calculator-icon-2.svg"
import Icon3 from "../../assets/images/temp/calculator-icon-3.svg"
import Icon4 from "../../assets/images/temp/calculator-icon-4.svg"
import Icon5 from "../../assets/images/temp/calculator-icon-5.svg"

const Icons = [Icon1, Icon2, Icon3, Icon4, Icon5]

const StyledRow = styled(Row)`
  display: flex;
`

const Section = styled.div`
  color: #fff;
  background: ${(props) => props.theme.colorBlack};
`

const Text = styled.h3.attrs({ className: `styled-h4` })`
  text-align: center;
`

const Circle = styled.div`
  display: inline-block;
  vertical-align: top;
  border-radius: 999em;
  background-color: #5b5550;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-position 0.3s;

  @media ${(props) => props.theme.xlargeUp} {
    width: ${rem(96)};
    height: ${rem(96)};
    background-size: ${rem(49)};
  }

  @media ${(props) => props.theme.xlargeDown} and ${(props) =>
      props.theme.smallUp} {
    width: ${rem(64)};
    height: ${rem(64)};
    background-size: ${rem(32)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(48)};
    height: ${rem(48)};
    background-size: ${rem(24)};
  }

  &:not(:first-of-type) {
    margin-left: ${rem(20)};
  }

  &:nth-of-type(3) {
    @media ${(props) => props.theme.xlargeUp} {
      background-size: ${rem(73)};
    }

    @media ${(props) => props.theme.xlargeDown} and ${(props) =>
        props.theme.smallUp} {
      background-size: ${rem(48)};
    }

    @media ${(props) => props.theme.smallDown} {
      background-size: ${rem(36)};
    }
  }

  &:last-of-type {
    background-color: #fff;

    @media ${(props) => props.theme.xlargeUp} {
      background-size: ${rem(58)};
    }

    @media ${(props) => props.theme.xlargeDown} and ${(props) =>
        props.theme.smallUp} {
      background-size: ${rem(38)};
    }

    @media ${(props) => props.theme.smallDown} {
      background-size: ${rem(29)};
    }
  }
`

const StyledCol = styled(Col)`
  @media ${(props) => props.theme.smallUp} {
    align-self: center;
    text-align: right;
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(20)};
    text-align: center;
  }

  a {
    white-space: nowrap;
    display: block;

    &:hover {
      ${Circle}:last-of-type {
        background-position: 70% 50%;
      }
    }
  }
`

const Calculator = (props) => (
  <Section>
    <Spacer top bottom>
      <Container>
        <StyledRow>
          <Col smallUp={5}>
            <Text>{props.content}</Text>
          </Col>

          <StyledCol smallUp={7}>
            <a href="/calculator" target="_blank">
              {Icons.map((item, index) => (
                <Circle
                  key={index}
                  style={{ backgroundImage: `url(${item})` }}
                />
              ))}
            </a>
          </StyledCol>
        </StyledRow>
      </Container>
    </Spacer>
  </Section>
)

export default Calculator

Calculator.propTypes = {
  content: PropTypes.string,
}
