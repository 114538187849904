import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Spacer from "../spacer"
import Container from "../container"

import Carousel from "../carousel"

import SliderArrowLeft from "../svg/carousel-arrow-left"
import SliderArrowRight from "../svg/carousel-arrow-right"
import { GrayH2Heading } from "../common/heading"

const ArrowLeftEl = styled.div`
  width: ${rem(32)};
  color: ${(props) => props.theme.colorPaleGrey};
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: ${rem(-40)};

  svg {
    fill: none;
  }

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(32)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(16)};
  }
`

const ArrowRightEl = styled.div`
  width: ${rem(32)};
  color: ${(props) => props.theme.colorPaleGrey};
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: ${rem(-40)};

  svg {
    fill: none;
  }

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(32)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(16)};
  }
`

const Content = styled.div.attrs({ className: `styled-h4` })`
  text-align: center;
  width: 100%;
`

const SliderContainer = styled.div`
  width: ${rem(930)};
  max-width: 100%;
  position: relative;
  margin: 0 auto;

  .carousel {
    width: ${rem(605)};
    max-width: 100%;
    margin: 0 auto;
    padding-right: ${rem(50)};
    padding-left: ${rem(50)};
  }
`

const Intro = (props) => {
  const slides = props.slideContent.map((item, index) => (
    <Content key={index} dangerouslySetInnerHTML={{ __html: item }} />
  ))

  return (
    <React.Fragment>
      <Spacer top>
        <Container>
          <GrayH2Heading dangerouslySetInnerHTML={{ __html: props.title }} />
        </Container>
      </Spacer>

      <Spacer top bottom>
        <Container>
          <SliderContainer>
            <Carousel hasDots visibleSlides={1} slides={slides}>
              <ArrowLeftEl>
                <SliderArrowLeft />
              </ArrowLeftEl>
              <ArrowRightEl>
                <SliderArrowRight />
              </ArrowRightEl>
            </Carousel>
          </SliderContainer>
        </Container>
      </Spacer>
    </React.Fragment>
  )
}

Intro.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.any,
  slideContent: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Intro
