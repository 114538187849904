import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Hero from "../components/hero"
import Intro from "../components/page-therapeutics/intro"
import CalculatorSection from "../components/page-therapeutics/calculator"
import SlidesSection from "../components/page-therapeutics/slides-section"
import BottomImageSection from "../components/page-therapeutics/bottom-image-section"

class DigitalTherapeuticsPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const content = {
      meta: {
        title: `What is a Digital Therapeutic?`,
        description: `Digital Therapeutics are mobile software applications that treat a disease or condition. Medical treatments. Use our Digital Therapeutic calculator to find out.`,
        keywords: `ClickTx, Treatment claims, regulatory authorization, prescription, Telehealth, mHealth`,
      },
      introSlider: {
        title: `What is a Digital Therapeutic?`,
        slideContent: [
          `Digital Therapeutics are mobile software applications that treat a disease or condition.`,
          `These therapeutic programs go through clinical trials and regulatory review, similar to a traditional drug or medical device.`,
          `Our Digital Therapeutics may be prescribed by your physician as a stand-alone treatment, or alongside a drug or therapy.`,
          `Digital Therapeutics use the strengths of mobile software to deliver an engaging and personalized treatment, right on your phone.`,
        ],
      },
      calculatorSection: {
        content: `Is your product a Digital Therapeutic? Use our Digital Therapeutic calculator to find out`,
        url: `/calculator`,
      },
      textSections: [
        {
          title: `What makes Digital Therapeutics different?`,
          slideContent: [
            `Digital Therapeutics are medical treatments. Our prescription Digital Therapeutics can make treatment claims and receive regulatory authorization.`,
            `The difference between a Digital Therapeutic and other health apps is similar to that between a supplement such as Vitamin C, and a prescription drug that you would pick up at the pharmacy.`,
            `Our therapies undergo a rigorous development process driven by the latest science, must demonstrate effectiveness in clinical studies, and are prescribed by a physician.`,
            `This is in contrast to digital health applications, which make wellness and lifestyle claims, can be used without a prescription, and are not reviewed by FDA for effectiveness. `,
          ],
        },
        {
          title: `Click’s Digital Therapeutics Platform`,
          slideContent: [
            `Our products are built on the Click Neurobehavioral Intervention (CNI) platform and powered by Clickometrics®, our machine learning and data science engine.`,
            `By taking a platform approach, Click is able to use learnings across our Digital Therapeutics to predict the optimal treatment journey for each patient.`,
            `As each product on our platform becomes better at engaging patients and delivering therapy, the other products also benefit.`,
            `This ability to continuously learn from and improve our products is unique to digital therapies, and at the center of our patients-first approach to responsive digital medicine.`,
          ],
        },
      ],
      bottomImage: {
        buttonText: `Learn more about how we help our patients`,
        url: `/for-patients`,
        image: this.props.data.bottomImage,
        altText: `Learn more about how we help our patients`,
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero
          image={this.props.data.heroImage}
          altText="Digital Therapeutics are mobile software applications that treat a disease or condition. Medical treatments. Use our Digital Therapeutic calculator to find out."
        />

        <Intro {...content.introSlider} />

        <CalculatorSection {...content.calculatorSection} />

        <SlidesSection textSections={content.textSections} />

        <BottomImageSection {...content.bottomImage} />
      </React.Fragment>
    )
  }
}

DigitalTherapeuticsPageContent.propTypes = {
  data: PropTypes.object,
}

const DigitalTherapeuticsPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: { eq: "temp/digital-therapeutics-page-hero-image.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
              quality: 60
            )
          }
        }

        bottomImage: file(
          sourceInstanceName: { eq: "images" }
          relativePath: {
            eq: "temp/digital-therapeutics-image-section-image.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: AUTO
              placeholder: BLURRED
              breakpoints: [
                320
                480
                640
                768
                960
                1024
                1200
                1400
                1680
                1920
              ]
              backgroundColor: "transparent"
              quality: 60
            )
          }
        }
      }
    `}
    render={(data) => <DigitalTherapeuticsPageContent data={data} {...props} />}
  />
)

export default DigitalTherapeuticsPage
