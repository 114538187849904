import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { gatsbyImageObject } from "../../config/sharp-image-proptypes"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import colors from "../../theme/sections/colors"

import Button from "../button"

import Row from "../grid/row"
import Col from "../grid/column"

// import Spacer from "../spacer"
import Container from "../container"
import Link from "../link"

const Section = styled.div`
  color: #333;
  position: relative;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: ${rem(50)};
`

const BottomImageSection = (props) => (
  <Section>
    <GatsbyImage
      backgroundColor={colors.colorSand}
      image={getImage(props.image)}
      alt={props.altText ?? ``}
    />

    <Bottom>
      <Container>
        <Row>
          <Col smallUpOffset={8} smallUp={4}>
            <Link to={props.url}>
              <Button>{props.buttonText}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Bottom>
  </Section>
)

BottomImageSection.propTypes = {
  url: PropTypes.string,
  image: gatsbyImageObject,
  buttonText: PropTypes.string,
  altText: PropTypes.string,
}

export default BottomImageSection
