import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"

import Spacer from "../spacer"
import Container from "../container"

import Carousel from "../carousel"

import SliderArrowLeft from "../svg/carousel-arrow-left"
import SliderArrowRight from "../svg/carousel-arrow-right"

const Section = styled.section`
  background: ${(props) => props.theme.colorSand};
`

const Heading = styled.h3.attrs({ className: `styled-cue` })`
  width: ${rem(400)};
  max-width: 100%;
`

const Divider = styled.div`
  height: 1px;
  background: ${(props) => props.theme.colorPaleGrey};
`

const ArrowLeftEl = styled.div`
  width: ${rem(32)};
  color: ${(props) => props.theme.colorPaleGrey};
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: ${rem(-40)};

  svg {
    fill: none;
  }

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(32)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(16)};
  }
`

const ArrowRightEl = styled.div`
  width: ${rem(32)};
  color: ${(props) => props.theme.colorPaleGrey};
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: ${rem(-40)};

  svg {
    fill: none;
  }

  @media ${(props) => props.theme.smallUp} {
    width: ${rem(32)};
  }

  @media ${(props) => props.theme.smallDown} {
    width: ${rem(16)};
  }
`

const Content = styled.div.attrs({ className: `styled-h4` })`
  text-align: center;
  width: 100%;
  color: #333;
`

const SliderContainer = styled.div`
  width: ${rem(930)};
  max-width: 100%;
  position: relative;
  margin: 0 auto;

  @media ${(props) => props.theme.smallDown} {
    margin-top: ${rem(30)};
  }

  .carousel {
    width: ${rem(605)};
    max-width: 100%;
    margin: 0 auto;
    padding-right: ${rem(50)};
    padding-left: ${rem(50)};
  }

  // temp
  .carousel__dot-group {
    text-align: center;

    .carousel__dot {
      width: ${rem(20)};
      height: ${rem(20)};
      margin: 0 ${rem(5)};
      border: 1px solid ${(props) => props.theme.colorPaleGrey};
      background: transparent;
      opacity: 1;
      border-radius: 999em;
    }

    .carousel__dot--selected {
      background: ${(props) => props.theme.colorPaleGrey};
    }
  }
`

const SlidesSection = (props) => (
  <Section>
    <Container>
      {props.textSections.map((item, index) => (
        <React.Fragment key={index}>
          <Spacer top bottom>
            <Row>
              <Col smallUp={6}>
                <Heading>{item.title}</Heading>
              </Col>

              <Col smallUp={6}>
                <SliderContainer>
                  <Carousel
                    hasDots
                    visibleSlides={1}
                    slides={item.slideContent.map((item, index) => (
                      <Content
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  >
                    <ArrowLeftEl>
                      <SliderArrowLeft />
                    </ArrowLeftEl>
                    <ArrowRightEl>
                      <SliderArrowRight />
                    </ArrowRightEl>
                  </Carousel>
                </SliderContainer>
              </Col>
            </Row>
          </Spacer>

          {index + 1 < props.textSections.length && (
            <Row>
              <Col smallUp={12}>
                <Divider />
              </Col>
            </Row>
          )}
        </React.Fragment>
      ))}
    </Container>
  </Section>
)

export default SlidesSection

SlidesSection.propTypes = {
  textSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slideContent: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  theme: PropTypes.any,
}
