import React from "react"

const CarouselArrowLeft = (props) => (
  <svg
    width="35"
    height="64"
    viewBox="0 0 35 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.3555 2.00293L3.00014 32.3584L33.3555 62.714"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
)

export default CarouselArrowLeft
