import React from "react"

const CarouselArrowRight = (props) => (
  <svg
    width="34"
    height="64"
    viewBox="0 0 34 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.35547 62.7139L31.7108 32.3584L1.35546 2.00284"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
)

export default CarouselArrowRight
